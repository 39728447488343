export const BONUS_POINT_ACTIVITY_TYPES = {
  support: 'Community support',
  content: 'Content creation',
  event: 'Event activity',
  social: 'Social engagement',
  other: 'Other',
};

export const REWARD_REASONS = {
  first_contribution: 'First contribution',
  hackathon: 'Hackathon',
  notable_contributor: 'Notable contributor (MVP)',
  other: 'Other',
};
