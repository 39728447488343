<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
} from 'bootstrap-vue-next';
import { daysAgo, firstOf } from '~/common/DateUtils';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  showCommunityOnly: {
    type: Boolean,
    default: false,
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
});

const periodDateRanges = {
  'Past 3 months': { fromDate: daysAgo(90), toDate: '' },
  'Past 6 months': { fromDate: daysAgo(180), toDate: '' },
  'Past year': { fromDate: daysAgo(365), toDate: '' },
  'This month': { fromDate: firstOf(), toDate: '' },
  'Last month': { fromDate: firstOf(1), toDate: firstOf() },
  'Custom': {},
};

const route = useRoute();
const router = useRouter();
const emits = defineEmits(['update:modelValue', 'filter']);

const filterData = ref({ ...props.modelValue });
const selectedPeriod = ref(null);

const searchLgSize = computed(() => props.showCommunityOnly && props.showSearch ? 12 : 3);

const normalize = value => (value == null || value === '') ? null : value;
const setSelectedPeriod = () => {
  for (const [period, range] of Object.entries(periodDateRanges)) {
    if (normalize(filterData.value.fromDate) == normalize(range.fromDate)
      && normalize(filterData.value.toDate) == normalize(range.toDate)) {
      selectedPeriod.value = period;
      return;
    }
  }

  selectedPeriod.value = 'Custom';
};

const emitFilter = () => {
  emits('update:modelValue', filterData.value);
  emits('filter');
};

const changePeriod = () => {
  if (selectedPeriod.value === 'Custom') return;

  const range = periodDateRanges[selectedPeriod.value];
  filterData.value.fromDate = range.fromDate;
  filterData.value.toDate = range.toDate;

  updateRoute();
};

const updateRoute = () => {
  const { fromDate, toDate, communityOnly, search } = filterData.value;
  const query = { fromDate, toDate };
  if (props.showSearch) query.search = search;
  if (props.showCommunityOnly) query.communityOnly = communityOnly.toString();

  router.push({ query });
};

const updateFilterData = (query) => {
  const { fromDate, toDate, communityOnly, search, page } = query;

  filterData.value = {
    fromDate: fromDate || daysAgo(90),
    toDate: toDate || '',
    communityOnly: communityOnly !== 'false',
    search: search || '',
    currentPage: page || 1,
  };

  emitFilter();
};

watch([() => filterData.value.fromDate, () => filterData.value.toDate], () => {
  setSelectedPeriod();
}, { immediate: true });

watch(() => route.query, (query, oldQuery = {}) => {
  if (window.history.state?.replaced) return; // initial page load
  const updated = [
    'fromDate',
    'toDate',
    'communityOnly',
    'search',
    'page',
  ].some(attr => query[attr] !== oldQuery[attr]);

  if (updated) updateFilterData(query);
}, { immediate: true });

onMounted(() => {
  updateFilterData(route.query);
});
</script>

<template>
  <BCard>
    <BRow class="gy-3 mb-3">
      <BCol
        v-if="showSearch"
        xs="9"
        :lg="searchLgSize"
      >
        <BFormGroup
          label="Name"
          label-for="search-string"
        >
          <BFormInput
            id="search-string"
            v-model="filterData.search"
            placeholder="Search"
            data-test-id="filter-card-search-input"
            @keyup.enter="updateRoute"
          />
        </BFormGroup>
      </BCol>
      <BCol
        xs="12"
        md="6"
        lg="3"
      >
        <BFormGroup
          label="Period"
          label-for="named-period"
        >
          <BFormSelect
            v-model="selectedPeriod"
            data-test-id="filter-card-named-period"
            :options="Object.keys(periodDateRanges)"
            @change="changePeriod"
          />
        </BFormGroup>
      </BCol>
      <BCol
        xs="12"
        md="6"
        lg="3"
      >
        <BFormGroup
          label="From date"
          label-for="from-date"
        >
          <BFormInput
            id="from-date"
            v-model="filterData.fromDate"
            data-test-id="filter-card-from-date"
            type="date"
          />
        </BFormGroup>
      </BCol>
      <BCol
        xs="12"
        md="6"
        lg="3"
      >
        <BFormGroup
          label="To date"
          label-for="to-date"
        >
          <BFormInput
            id="to-date"
            v-model="filterData.toDate"
            data-test-id="filter-card-to-date"
            type="date"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="showCommunityOnly"
        xs="12"
        md="6"
        lg="3"
      >
        <BFormGroup
          label="Community only"
          label-for="community-only"
        >
          <BFormCheckbox
            id="community-only"
            v-model="filterData.communityOnly"
            data-test-id="filter-card-community-only"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol
        class="d-flex justify-content-end"
      >
        <BButton
          data-test-id="filter-card-search-button"
          variant="primary"
          @click="updateRoute"
        >
          Filter
        </BButton>
      </BCol>
    </BRow>
  </BCard>
</template>
