<script setup>
import { BButton } from 'bootstrap-vue-next';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { mergeRequestDetailsQuery } from '../graphql/mergeRequestDetails.query.graphql';

const route = useRoute();
const { mergeRequestId } = route.params;

const { result } = useQuery(mergeRequestDetailsQuery, { mergeRequestId: `gid://gitlab/MergeRequest/${mergeRequestId}` });

const mergeRequest = computed(() => result.value?.mergeRequest);

/* eslint-disable no-console */
const request = async (what) => {
  try {
    const projectId = mergeRequest.value.project.id.split('/').pop();

    const response = await fetch('/api/v1/manage_merge_request_bot_request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ project_id: projectId, merge_request_iid: mergeRequest.value.iid, what }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Help requested successfully:', data);

    window.location.href = mergeRequest.value.webUrl;
  } catch (error) {
    console.error('There was a problem with the request:', error);
  }
};
</script>
/* eslint-enable no-console */

<template>
  <h1>Manage Merge Request</h1>
  <div v-if="mergeRequest">
    <a :href="mergeRequest.project.webUrl">{{ mergeRequest.project.name }}</a>:
    <a :href="mergeRequest.webUrl">{{ mergeRequest.title }}</a>
    <br>
    <br>
    <BButton
      id="request-review"
      class="me-2"
      @click="request('review')"
    >
      Request Review
    </BButton>
    <BButton
      id="request-help"
      @click="request('help')"
    >
      Request Help
    </BButton>
  </div>
  <div v-else>Loading...</div>
</template>
