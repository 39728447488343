<script setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { BOverlay, BPagination } from 'bootstrap-vue-next';
import { buildUrl } from '~/common/UrlUtils';
import FilterCard from '~/common/components/FilterCard.vue';
import ContributorsTable from '~/common/components/ContributorsTable.vue';

const leaders = ref([]);
const busy = ref(true);
const filterData = ref({
  search: '',
  fromDate: '',
  toDate: '',
  communityOnly: true,
  currentPage: 1,
});
const pageData = ref({
  perPage: 50,
  totalRows: 0,
});

const router = useRouter();
const route = useRoute();

const onPageChange = (newPage) => {
  const query = route.query;
  router.push({ query: { ...query, page: newPage } });
};

const fetchData = async () => {
  busy.value = true;
  const query = {
    from_date: filterData.value.fromDate,
    to_date: filterData.value.toDate,
    community_only: filterData.value.communityOnly,
    page: filterData.value.currentPage,
  };
  const search = filterData.value.search;
  if (search.length > 0) query.search = search;
  const url = buildUrl('/api/v1/leaderboard', { query });

  const response = await fetch(url);
  const { records, metadata } = await response.json();
  leaders.value = records;
  pageData.value = {
    perPage: metadata.per_page,
    totalRows: metadata.total_count,
  };

  busy.value = false;
};
</script>

<template>
  <FilterCard
    v-model="filterData"
    show-community-only
    show-search
    @filter="fetchData"
  />

  <BOverlay
    :show="busy"
    no-wrap
    fixed
  >
    <ContributorsTable
      :contributors="leaders"
      :busy="false"
      :filter-data="filterData"
    />
    <BPagination
      v-model="filterData.currentPage"
      :total-rows="pageData.totalRows"
      :per-page="pageData.perPage"
      align="center"
      first-number
      last-number
      @update:model-value="onPageChange($event)"
    />
  </BOverlay>
</template>
