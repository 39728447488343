<script setup>
import { inject } from 'vue';
import {
  BCollapse,
  BDropdownItem,
  BNavbar,
  BNavbarBrand,
  BNavItem,
  BNavItemDropdown,
  BNavbarNav,
  BNavbarToggle,
} from 'bootstrap-vue-next';
import ContributorLevelBadge from '~/common/components/ContributorLevelBadge.vue';
import Logo from '../../../assets/images/gitlab_logo.svg';

const vueData = inject('vueData');
const username = vueData.username;
const admin = vueData.userAdmin;
</script>

<template>
  <BNavbar
    class="navbar-dark"
    toggleable="sm"
  >
    <BNavbarToggle
      target="main-nav-collapse"
    />
    <BNavbarBrand
      tag="h1"
      class="mb-0"
      :to="{ name: 'home' }"
    >
      <img
        class="navbar-brand-logo"
        :src="Logo"
        alt="GitLab logo"
      >
      <span class="navbar-brand-text ms-3">Contributors</span>
    </BNavbarBrand>
    <BCollapse
      id="main-nav-collapse"
      is-nav
    >
      <BNavbarNav>
        <BNavItem :to="{ name: 'leaderboard' }">
          Leaderboard
        </BNavItem>
        <BNavItem :to="{ name: 'organizations' }">
          Organizations
        </BNavItem>
        <BNavItem
          v-if="admin"
          :to="{ name: 'rewards' }"
        >
          Rewards
        </BNavItem>
      </BNavbarNav>
      <BNavbarNav class="ms-auto mb-lg-0">
        <BNavItemDropdown
          v-if="username"
        >
          <template #button-content>
            <ContributorLevelBadge
              :contributor-level="vueData.contributorLevel"
              :badge-size="25"
            />
            <span class="mx-2">{{ username }}</span>
          </template>
          <BDropdownItem :to="{ name: 'user', params: { userId: vueData.userId } }">
            Profile
          </BDropdownItem>
          <BDropdownItem :href="`https://gitlab.com/${username}`">
            GitLab Profile
          </BDropdownItem>
          <BDropdownItem href="/logout">
            Logout
          </BDropdownItem>
        </BNavItemDropdown>
        <BNavItem
          v-else
          href="/login"
        >
          Login
        </BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
</template>
