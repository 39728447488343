export const daysAgo = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString().split('T')[0];
};

export const firstOf = (offset = 0) => {
  const now = new Date();
  const date = new Date(Date.UTC(now.getFullYear(), now.getMonth() - offset, 1));
  return date.toISOString().split('T')[0];
};
