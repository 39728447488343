<script setup>
import { ref } from 'vue';
import { buildUrl } from '~/common/UrlUtils';
import { REWARD_REASONS } from '~/common/Constants';
import { dateFormatter } from '~/common/FieldFormats';
import { BButton, BLink, BOverlay, BPagination, BTable } from 'bootstrap-vue-next';
import IssueRewardModal from '~/common/components/IssueRewardModal.vue';

const rewards = ref([]);
const filterData = ref({
  currentPage: 1,
});
const pageData = ref({
  perPage: 50,
  totalRows: 0,
});
const busy = ref(true);
const showIssueRewardModal = ref(false);

const fields = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'reason',
    label: 'Reason',
    formatter: value => REWARD_REASONS[value],
  },
  {
    key: 'gift_code',
    label: 'Gift code',
  },
  {
    key: 'credits',
    label: 'Credits',
  },
  {
    key: 'awarded_by',
    label: 'Issued by',
  },
  {
    key: 'created_at',
    label: 'Issued date',
    formatter: dateFormatter,
  },
];

const fetchData = async (page) => {
  busy.value = true;
  const url = buildUrl('/api/v1/rewards', { query: { page } });
  const response = await fetch(url);
  const { records, metadata } = await response.json();
  rewards.value = records;
  pageData.value = {
    perPage: metadata.per_page,
    totalRows: metadata.total_count,
  };
  busy.value = false;
};

fetchData(1);
</script>

<template>
  <BOverlay
    :show="busy"
    no-wrap
    fixed
  >
    <BButton
      variant="outline-primary"
      @click="showIssueRewardModal = true"
    >
      Issue rewards
    </BButton>
    <BTable
      :fields="fields"
      :items="rewards"
      show-empty
    >
      <template #cell(username)="cell">
        <BLink
          :to="{
            name: 'user',
            params: { userId: cell.item.user_id },
          }"
        >
          {{ cell.value }}
        </BLink>
      </template>
      <template #cell(gift_code)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.issue_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
      <template #cell(awarded_by)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.awarded_by_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
    </BTable>
    <BPagination
      v-model="filterData.currentPage"
      :total-rows="pageData.totalRows"
      :per-page="pageData.perPage"
      align="center"
      first-number
      last-number
      @update:model-value="fetchData($event)"
    />
  </BOverlay>

  <IssueRewardModal
    v-model="showIssueRewardModal"
  />
</template>
