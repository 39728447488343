<script setup>
import { BContainer } from 'bootstrap-vue-next';
import NavigationMenu from '~/common/components/NavigationMenu.vue';
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const showNav = computed(() => {
  return route?.name !== 'manage-merge-request';
});
const DEFAULT_TITLE = 'Contributors';

const vueData = inject('vueData');

router.beforeEach((to) => {
  const loggedIn = vueData?.userId != null;
  const admin = vueData?.userAdmin;

  const { requireAdmin, requireLogin, title } = to.meta;

  if (!loggedIn && (requireAdmin || requireLogin)) {
    window.location.href = `/login?return=${to.fullPath}`;
    return false;
  } else if (requireAdmin && !admin) {
    return '/';
  }

  document.title = title || DEFAULT_TITLE;
});
</script>

<template>
  <NavigationMenu v-if="showNav" />
  <BContainer class="pt-2">
    <router-view />
    <hr>
    <p>
      <a
        href="https://gitlab.com/gitlab-org/developer-relations/contributor-success/contributors-gitlab-com"
        target="_blank"
      >View source</a>
      ||
      <!-- OneTrust Cookies Settings button start -->
      <a
        class="ot-sdk-show-settings"
        href="#"
      >
        Cookie settings
      </a>
      <!-- OneTrust Cookies Settings button end -->
    </p>
  </BContainer>
</template>
