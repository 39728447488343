import HomePage from '~/pages/HomePage.vue';
import LeaderboardPage from '~/pages/LeaderboardPage.vue';
import ManageMergeRequestPage from '~/pages/ManageMergeRequestPage.vue';
import UserEditPage from '~/pages/UserEditPage.vue';
import UserPage from '~/pages/UserPage.vue';
import RewardsPage from './pages/RewardsPage.vue';
import OrganizationsPage from '~/pages/OrganizationsPage.vue';
import OrganizationPage from '~/pages/OrganizationPage.vue';

export default [
  {
    name: 'home',
    path: '/',
    component: HomePage,
  },
  {
    name: 'leaderboard',
    path: '/leaderboard',
    component: LeaderboardPage,
    meta: { title: 'Leaderboard' },
  },
  {
    name: 'manage-merge-request',
    path: '/manage-merge-request/:mergeRequestId',
    component: ManageMergeRequestPage,
    meta: {
      requireLogin: true,
      title: 'Merge request details',
    },
  },
  {
    name: 'rewards',
    path: '/rewards',
    component: RewardsPage,
    meta: {
      requireAdmin: true,
      title: 'Rewards',
    },
  },
  {
    name: 'user',
    path: '/users/:userId',
    component: UserPage,
  },
  {
    name: 'user-edit',
    path: '/users/:userId/edit',
    component: UserEditPage,
    meta: { requireAdmin: true },
  },
  {
    name: 'organizations',
    path: '/organizations',
    component: OrganizationsPage,
    meta: { title: 'Organizations leaderboard' },
  },
  {
    name: 'organization',
    path: '/organizations/:organizationName',
    component: OrganizationPage,
  },
];
