export const buildUrl = (path, { query } = {}) => {
  const url = new URL(path, window.location.href);

  if (query) {
    Object.keys(query).forEach((key) => {
      url.searchParams.append(key, query[key]);
    });
  }

  return url;
};
