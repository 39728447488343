<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { buildUrl } from '~/common/UrlUtils';
import FilterCard from '~/common/components/FilterCard.vue';
import ContributorsTable from '~/common/components/ContributorsTable.vue';

const route = useRoute();
const { organizationName } = route.params;
const organization = ref({ name: organizationName, contributors: [] });
const totals = ref(null);
const busy = ref(true);

const filterData = ref({
  fromDate: '',
  toDate: '',
});

const fetchData = async () => {
  const name = organization.value.name;
  const query = {
    from_date: filterData.value.fromDate,
    to_date: filterData.value.toDate,
    id: name,
  };
  const url = buildUrl('/api/v1/organizations', { query });

  const response = await fetch(url);
  organization.value = await response.json();
  if (organization.value.contributors.length > 1) totals.value = calculateTotal();
  busy.value = false;
};

const calculateTotal = () => {
  const initSum = {
    merged_merge_requests: 0,
    merged_with_issues: 0,
    opened_merge_requests: 0,
    opened_issues: 0,
    merged_commits: 0,
    added_notes: 0,
    bonus_points: 0,
    score: 0,
  };
  const contributors = organization.value.contributors;

  return contributors.reduce((sum, contributor) => {
    Object.keys(sum).forEach((contributionType) => {
      sum[contributionType] += contributor[contributionType];
    });
    return sum;
  }, initSum);
};

onMounted(() => {
  document.title = organizationName;
});
</script>

<template>
  <div>
    <h1 class="my-4">
      {{ organization.name }}
    </h1>

    <FilterCard
      v-model="filterData"
      class="my-3"
      @filter="fetchData"
    />

    <h2>Contributors</h2>
    <ContributorsTable
      :contributors="organization.contributors"
      :totals="totals"
      :busy="busy"
      :filter-data="filterData"
    />
  </div>
</template>
